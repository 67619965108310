import React, {useEffect, useState} from 'react';
import Movie from './components/Movie';

const FEATURED_API = "https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=4072a43e4a6f136e5357adc470db1194&page=1&language=pt-BR";
const SEARCH_API = "https://api.themoviedb.org/3/search/movie?api_key=4072a43e4a6f136e5357adc470db1194&page=1&language=pt-BR&query=";

const Emoji = props => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
)

function App() {
  
  const [ movies, setMovies ] = useState([]);
  const [ searchTerm, setSerachTerm ] = useState('');
  

  useEffect(() =>{
    getMovies(FEATURED_API);
   }, []);

   const getMovies = (API) => {
    fetch(API)
    .then(res => res.json())
    .then(data => {
      setMovies(data.results);
    });
   }

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if(searchTerm) {
      getMovies(SEARCH_API + searchTerm);
      setSerachTerm('');
     
    }
  };

  const handleChange = (e) => {
    setSerachTerm(e.target.value);
  }


  return (
    <>
     <header>
       <div>
        <h1><a href="/">Movies DB</a></h1>
        <form onSubmit={handleOnSubmit}>
            <input className="search" type="search" value={searchTerm} onChange={handleChange} placeholder="Digite o nome do filme.."/>
            <button onClick={handleOnSubmit} className="search-submit" type="submit">
              <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                <path d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
              </svg>
            </button>
        </form>
      </div>
      </header>
      
      <div className="movie-container">
        {(movies.length > 0 ? movies.map((movie) => <Movie key={movie.id} {...movie} />) :
         <div className="not-found">
          <h2>Nenhum filme encontrado</h2>
          <p>Utilize a busca para tentar novamente.</p>
         </div>
         )}
      </div>
      <svg width="0" height="0" x="0px" y="0px">
        <defs>
          <clipPath id="wave" clipPathUnits="objectBoundingBox">
          <path d="M1.5,0H1H0.5H0v0.8C0.3,0.8,0.3,1,0.5,1c0,0,0,0,0,0C0.8,1,0.8,0.8,1,0.8c0,0,0,0,0,0C1.3,0.8,1.3,1,1.5,1C1.8,1,1.8,0.8,2,0.8V0H1.5z"/>
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0" to="-250 0"
              begin="0s" dur="5s"
              repeatCount="indefinite" 
            />
         </clipPath>
       </defs>
      </svg>

      <footer>
        <p>Feito com muito <Emoji symbol="💜"></Emoji> por Thiago Silva</p>
      </footer>

    </>
  );
}

export default App;
